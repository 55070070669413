import React, { ReactElement, ReactNode, useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import uniqueId from 'lodash/uniqueId';
import * as Styled from './styles';
import { ButtonType, FeatureType } from './types';
import pricingFeatureDivider from '../../assets/pricing-feature-divider.svg';
import crown from '../../assets/icon-crown.svg';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';
import { useLocation } from '@reach/router';

type Price = 'annual' | 'monthly';

interface PriceData {
  name: string | ReactNode;
  price: Record<
    Price,
    {
      amount: number;
      url: string;
    }
  >;
  buttonType: ButtonType;
  featuresStyles: SerializedStyles;
  features: {
    type: FeatureType;
    description: string | ReactNode;
  }[];
}

const DATA: PriceData[] = [
  {
    name: 'Standard Lifetime',
    buttonType: 'outline',
    price: {
      deal: "standard",
      amount: 49,
      url: 'https://checkout.sketchwow.com?add-to-cart=559114',
    },
    featuresStyles: css`
      margin-top: 92px;
      @media screen and (max-width: 1020px) {
        margin-top: 35px;
      }
    `,
    features: [
      // {
      //   type: 'contains',
      //   description: 'Up to 40 elements per document',
      // },
      {
        type: 'contains',
        description: 'Up to 10 pages per document',
      },
      {
        type: 'contains',
        description: 'Unlimited sketches',
      },
      {
        type: 'contains',
        description: '44 templates',
      },
      {
        type: 'contains',
        description: '238 elements, shapes, icons',
      },
      {
        type: 'contains',
        description: 'Standard color backgrounds',
      },
      {
        type: 'contains',
        description: '15 fonts (+ import custom fonts)',
      },
      {
        type: 'contains',
        description: 'Resize canvas (Custom size)',
      },
      {
        type: 'contains',
        description: 'Import Images (Drag-and-drop)',
      },
      {
        type: 'contains',
        description: 'JPG, PNG, PDF, Animated GIF (Export)',
      },
      {
        type: 'contains',
        description: 'Presentation mode',
      },
      {
        type: 'contains',
        description: 'File backup & restore',
      },
      {
        type: 'contains',
        description: 'Use on multiple computers',
      },
      {
        type: 'contains',
        description: 'Standard support',
      },
      // {
      //   type: 'excludes',
      //   description: 'Get access to new shapes, images & templates',
      // },
    ],
  },
  {
    name: (
      <span
        css={css`
          position: relative;

          &:after {
            content: '';
            width: 24px;
            height: 22px;
            position: absolute;
            top: -5px;
            left: -13px;
            background: url(${crown}) no-repeat 0 0;
            background-size: contain;
          }
        `}
      >
        Pro Lifetime
      </span>
    ),
    buttonType: 'solid',
    price: {
      deal: "pro",
      amount: 118,
      url: 'https://checkout.sketchwow.com?add-to-cart=559104',
    },
    featuresStyles: css`
      margin-top: 36px;
    `,
    features: [
      {
        type: 'contains',
        description: (
          <div
            css={css`
              max-width: calc(100% - 51px);

              &:after {
                content: '';
                display: block;
                width: 321px;
                max-width: calc(100% + 51px);
                height: 12px;
                margin-top: 8px;
                margin-bottom: -12px;
                margin-left: -51px;
                background: url(${pricingFeatureDivider}) no-repeat 0 0;
                background-size: contain;
              }
            `}
          >
            Everything&nbsp;<strong>Standard</strong>&nbsp;has, plus:
          </div>
        ),
      },
      // {
      //   type: 'additional',
      //   description: (
      //     <div>
      //       <strong>Unlimited</strong>&nbsp;elements per document
      //     </div>
      //   ),
      // },
      {
        type: 'additional',
        description: (
          <div>
            <strong>Unlimited</strong>&nbsp;pages per document
          </div>
        ),
      },
      {
        type: 'additional',
        description: (
          <div>
            <strong>Unlimited</strong>&nbsp;sketches
          </div>
        ),
      },
      {
        type: 'additional',
        description: (
          <div>
            <strong>100+</strong>&nbsp;premium templates
          </div>
        ),
      },
      {
        type: 'additional',
        description: (
          <div>
            <strong>500+</strong>&nbsp;elements, shapes, icons
          </div>
        ),
      },
      {
        type: 'additional',
        description: (
          <div>
            <strong>10</strong>&nbsp;exclusive background images
          </div>
        ),
      },
      {
        type: 'additional',
        description: (
          <div>
            <strong>30</strong>&nbsp;Fonts (+ import custom fonts)
          </div>
        ),
      },
      {
        type: 'additional',
        description: 'Resize canvas (Custom size)',
      },
      {
        type: 'contains',
        description: 'Import Images (Drag-and-drop)',
      },
      {
        type: 'contains',
        description: 'JPG, PNG, PDF, Animated GIF (Export)',
      },
      {
        type: 'contains',
        description: 'Presentation mode',
      },
      {
        type: 'contains',
        description: 'File backup & restore',
      },
      {
        type: 'contains',
        description: 'Use on multiple computers',
      },
      {
        type: 'contains',
        description: (
          <div>
            <strong>Premium</strong>&nbsp;support
          </div>
        )
        
      },
      // {
      //   type: 'additional',
      //   description: 'Get access to new shapes, images & templates',
      // },
    ],
  },
];

const SUBTITLE_BY_TYPE: Record<Price, string> = {
  standard: 'Regular Price: $228/Year',
  pro: 'Regular Price: $408/Year',
};

interface LifetimeUpgradePricingProps {
  styles?: any;
}

export const LifetimeUpgradePricing = ({ styles }: LifetimeUpgradePricingProps): ReactElement => {
	const location = useLocation();
  useEffect(()=>{
    const deal_qstr =  window.location.href.split('?')[1];
    if(typeof deal_qstr !== 'undefined' && document.getElementById("lifetime-deal-standard-cart-url") !== null){
      document.getElementById("lifetime-deal-standard-cart-url").href = "https://checkout.sketchwow.com/?add-to-cart=559114&" + deal_qstr;
    }
    if(typeof deal_qstr !== 'undefined' && document.getElementById("lifetime-deal-pro-cart-url") !== null){
      document.getElementById("lifetime-deal-pro-cart-url").href = "https://checkout.sketchwow.com/?add-to-cart=559104&" + deal_qstr;
    }

   }, []);

  const memoizedData = useMemo(
    () =>
      DATA.map((item) => ({
        ...item,
        id: uniqueId(),
        features: item.features.map((feature) => ({
          ...feature,
          id: uniqueId(),
        })),
      })),
    [],
  );

  const pricingRef = useRef(null);
  const stickyDetectionRef = useRef(null);
  
  const { isVisible } = useVisibilityDetection(pricingRef, [0.1, 0.9]);
  const { isVisible: shouldHideTopBar } = useVisibilityDetection(stickyDetectionRef, [0, 1], true);

  const [priceType, setPriceType] = useState<Price>('standard');

  const handleBillingChange = useCallback(() => {
    setPriceType(priceType === 'pro' ? 'standard' : 'pro');
  }, [priceType]);
  
  return (
    <Styled.Wrapper isVisible={isVisible} ref={pricingRef} id="LifetimeUpgradePricing"
    css={css`
      ${styles}
    `}>

      <Styled.ListWrapper>
        <Styled.ListTopBackground
          css={css`
          max-width: 960px;
          `} 
        />
        <Styled.List>
          {memoizedData.map(({ buttonType, featuresStyles, features, id, name, price }) => (
            <Styled.PricingColumn key={id}>
              <Styled.Heading>
                <Styled.Title>{name}</Styled.Title>
                <Styled.SubTitle>{SUBTITLE_BY_TYPE[price.deal]}</Styled.SubTitle>
                <Styled.Price>
                  <Styled.PriceVault>$</Styled.PriceVault>
                  <Styled.PriceAmount>{price.amount}</Styled.PriceAmount>
                  
                </Styled.Price>
                <Styled.PricePeriod>One-time payment</Styled.PricePeriod>
                <Styled.Button id={'lifetime-deal-' + price.deal + '-cart-url'} href={price.url} type={buttonType}>
                  Buy Now
                </Styled.Button>
              </Styled.Heading>
              <Styled.Features css={featuresStyles}>
                {features.map(({ description, id: featureId, type }) => (
                  <Styled.Feature key={featureId} type={type}>
                    {description}
                  </Styled.Feature>
                ))}
              </Styled.Features>
            </Styled.PricingColumn>
          ))}
        </Styled.List>
        <Styled.ListBottomBackground 
          css={css`
          max-width: 960px;
         `}
        />
      </Styled.ListWrapper>

      <Styled.BackgroundWrapper>
        <Styled.Background>
          <Styled.BackgroundIllustrations />
        </Styled.Background>
      </Styled.BackgroundWrapper>



    </Styled.Wrapper>
  );
};
