import React, {
  ReactElement,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import uniqueId from "lodash/uniqueId";
import { TestimonialsItem } from "./types";
import * as Styled from "./styles";
import useVisibleOnScroll from "../../hooks/useVisibleOnScroll";

interface TestimonialsProps {
  data: TestimonialsItem[];
  title: string;
}

interface TestimonialsDataItem extends TestimonialsItem {
  id: string;
}

export const Testimonials = ({
  data,
  title,
}: TestimonialsProps): ReactElement => {
  const memoizedData = useMemo(
    () =>
      data.map(
        (item): TestimonialsDataItem => ({
          ...item,
          id: uniqueId(),
        })
      ),
    [data]
  );

  //const [activeQuote, setActiveQuote] = useState(memoizedData[0].id);
  const testimonialsRef = useRef(null);
  const { isVisible } = useVisibleOnScroll(testimonialsRef);

  return (
    <Styled.Wrapper isVisible={isVisible} ref={testimonialsRef}>
      <Styled.Heading>
        <Styled.Title>{title}</Styled.Title>
      </Styled.Heading>

      <Styled.Grid>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(0, 2).map(({ id, image }) => (
              <Styled.ImageContainer width="49%">{image}</Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(2, 5).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(5, 7).map(({ id, image }) => (
              <Styled.ImageContainer width="49%">{image}</Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(7, 9).map(({ id, image }) => (
              <Styled.ImageContainer width="49%">{image}</Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(9, 12).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(12, 15).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(15, 17).map(({ id, image }) => (
              <Styled.ImageContainer width="49%">{image}</Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(17, 19).map(({ id, image }) => (
              <Styled.ImageContainer width="49%">{image}</Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(19, 21).map(({ id, image }) => (
              <Styled.ImageContainer width="49%">{image}</Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(21, 24).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(24, 28).map(({ id, image }) => (
              <Styled.ImageContainer width="24%">{image}</Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>

        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(28, 31).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>

        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(31, 35).map(({ id, image }) => (
              <Styled.ImageContainer width="24%">{image}</Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
      </Styled.Grid>

      <Styled.Doodle />
      <Styled.Arrow
        src="/testimonials-arrow.webp"
        srcSet="/testimonials-arrow.webp 1x, /testimonials-arrow@2x.webp 2x"
        alt="Arrow"
        loading="lazy"
      />
    </Styled.Wrapper>
  );
};
