import React, { ReactElement, useRef } from 'react';
import * as Styled from './styles';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';
import { useLocation } from '@reach/router';

interface SignupProps {
  subTitle: ReactElement | string;
  title: ReactElement | string;
}

export const Signup = ({ subTitle, title }: SignupProps): ReactElement => {
  const signupRef = useRef(null);
  const { isVisible } = useVisibilityDetection(signupRef);
  const location = useLocation();
  const link = () => {

    if( location.pathname == '/pricing/' || location.pathname == '/pricing'){
      return(<Styled.Button  href="#pricing">Get SketchWow</Styled.Button>)
    }else{
      return(<Styled.Button href="/pricing">Get SketchWow</Styled.Button> )
    }

  }

  return (
    <Styled.SafariWrapper>
      <Styled.Wrapper isVisible={isVisible} ref={signupRef}>
        <Styled.Heading>
          <Styled.Title>{title}</Styled.Title>
          <Styled.SubTitle>{subTitle}</Styled.SubTitle>
        </Styled.Heading>
        <Styled.Section>

          { link() }

        </Styled.Section>
        <Styled.Footer>
          <p>
            SketchWow works flawlessly on <Styled.Doodle>Windows & Macs.</Styled.Doodle>
          </p>
        </Styled.Footer>
      </Styled.Wrapper>
    </Styled.SafariWrapper>
  );
};
